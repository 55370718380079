* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow: hidden;
}

body {
  user-select: none;
  font-family: "Love Ya Like A Sister", cursive;
}

img {
  pointer-events: none;
}
