.splash {
  position: fixed;
  inset: 0;
  background-color: hsla(0deg 0% 100% / 75%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 64px;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.5s, transform 1s;
}

.splash.hidden {
  pointer-events: none;
  transform: scale(1.5);
  opacity: 0;
}

.splash h1 {
  font-size: 6vw;
  color: hsl(81deg 59% 44%);
  text-shadow: 5px 5px 0 black;
}

.splash button {
  font-family: inherit;
  padding: 10px 15px;
  font-size: 38px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2em;
  border: 4px solid black;
  color: black;
  background-color: hsla(0deg 0% 100% / 75%);
  cursor: pointer;
}
