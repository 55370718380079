.gem-counter {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 15px;
  background-color: hsla(0deg 0% 100% / 80%);
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
}

.gem-counter > img {
  height: 52px;
}

.gem-counter > span {
  width: 80px;
  text-align: center;
  color: black;
  line-height: 1;
  font-size: 52px;
  font-weight: bold;
}

.gems-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}
